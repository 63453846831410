import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { RegistrationData } from '../types/Auth';
import axios from 'axios';


const registrationUrl : string = process.env.REACT_APP_API_URL + "/auth/register";

interface RegistrationFormProps {
    onSwitchToLogin: () => void;
  }
  


const RegistrationForm: React.FC<RegistrationFormProps> = ({ onSwitchToLogin }: { onSwitchToLogin: () => void }) => {
  const [formData, setFormData] = useState<RegistrationData>({
    organizationName: 'org02',
    username: 'org02_user01',
    email: 'user01@org02.com',
    password: 'password',
   // confirmPassword: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //if (formData.password !== formData.confirmPassword) {
    //  setError('Passwords do not match');
    //  return;
    // }
    try {
      console.log('Form Data:', formData);
      //const response = await axios.get('https://www.cnn.com');
      const response = await axios.post(
                    //'http://localhost:8080/auth/register', 
                    registrationUrl,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
      //const response = await axiosInstance.post('/auth/register', formData);
      console.log('response:', response);
      onSwitchToLogin();
      setError(null);
    } 
    catch (error) {
        console.error('Request failed:', error);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error('Server responded with an error:', error.response);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
        } else {
          console.error('Unexpected error:', error);
        }  
    }
  };

  if (success) {
    return <p>Registration successful! Please log in.</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="organizationName" placeholder="OrganizationName" value={formData.organizationName} onChange={handleChange} required />
      <input type="text" name="username" placeholder="UserName" value={formData.username} onChange={handleChange} required />
      <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
      <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
      <button type="submit">Register</button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default RegistrationForm;
