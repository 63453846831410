import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { diag, DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api';

// Optional: Enable diagnostic logging for OpenTelemetry
diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG);

// Define the resource with the service name
const resource = new Resource({
  'service.name': 'babahanks-gui', // Use the string literal for service name
});

// Create and configure the tracer provider
const provider = new WebTracerProvider({
  resource: resource,
});

// Configure the OTLP HTTP exporter
const exporter = new OTLPTraceExporter({
  url: 'http://localhost:4318/v1/traces', // Replace with your OTLP endpoint
});

// Add a BatchSpanProcessor to handle exporting spans
provider.addSpanProcessor(new BatchSpanProcessor(exporter));

// Register the provider to start tracing
provider.register();

// Register automatic instrumentation for web libraries
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations(),
  ],
});