import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import axios from 'axios';
import { LoginData, LoginResponse } from '../types/Auth';

interface LoginFormProps {
    onLoginSuccess: (token: string) => void;
  }

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
  const [formData, setFormData] = useState<LoginData>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    console.log("Form data before submission:", formData);
    e.preventDefault();
    try {
      console.log(" IN submission");
      const response = await axiosInstance.post<LoginResponse>('/auth/login', formData);
      //const url = "http://security.local:8081/auth/login"; // Replace with your API URL
      //const response = await axiosInstance.post<LoginResponse>('babahanks-v2-lb-1157866590.us-east-2.elb.amazonaws.com:8081/auth/login', formData);

      onLoginSuccess(response.data.token);
      setError(null);
    } catch (err: any) {
      console.error("Error occurred:", err);
      setError(err.response.data.message || 'An error occurred');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
      <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
      <button type="submit">Login</button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default LoginForm;
