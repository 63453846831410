import axios, { AxiosInstance } from 'axios';

//const baseURL : string = process.env.REACT_APP_API_URL as string;
//const baseURL : string = process.env.REACT_APP_API_URL as string;

//const baseURL : string = "http://security.local:8081";

const baseURL: string = 'http://babahanks-v2-external-lb-1764019540.us-east-2.elb.amazonaws.com:8081';
console.log("baseURL:", { baseURL });
/*
if (!baseURL || baseURL.trim() === "") {
  console.log("baseURL is null or empty");
  throw new Error("REACT_APP_API_URL has not been set.");
}
*/
const axiosInstance: AxiosInstance = axios.create({
  //baseURL: baseURL,
  baseURL: 'http://babahanks-v2-external-lb-1764019540.us-east-2.elb.amazonaws.com:8081',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;