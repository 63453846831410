import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Category, Parameter } from '../types/Category';
import DynamicForm from './DynamicForm';
import { SearchParameter, SearchFilter } from '../types/SearchFilter';
import axiosInstance from '../api/axiosInstance';


interface CategoryComponentProps {
  token: string | null;
}





const CategoryComponent: React.FC<CategoryComponentProps> = ({ token }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [parameters, setParameters] = useState<Parameter[]>([]);

  const categoriesUrl : string = process.env.REACT_APP_API_URL + "/categories";
  const inventroriesSearchUrl : string = process.env.REACT_APP_API_URL + "/inventories/search";

  /*
  useEffect(() => {
    // Fetch categories on component mount
    axios.get(
      //'http://localhost:8080/categories', 
      // categoriesUrl,
       'http://babahanks-v2-lb-10487527.us-east-2.elb.amazonaws.com:8081/categories',
      {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);
*/
  useEffect(() => {
    // Fetch categories on component mount
    axiosInstance.get('/categories',
      {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const category = categories.find(cat => cat.id === selectedId) || null;
    setSelectedCategory(category);
  };
    /*
    // Fetch category parameters
    axios.get(`http://localhost:8080/categories/id/${categoryId}`, 
      {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      })
      .then(response => {
        setParameters(response.data);
      })
      .catch(error => {
        console.error('Error fetching category parameters:', error);
      }); */
  
  const handleFormSubmit = (searchFilter: SearchFilter) => {
    console.log('Submitted Search Filter:', searchFilter);
    const response = inventorySearch(searchFilter);
    // You can now use the searchFilter object as needed, e.g., send it to an API
  };


  const inventorySearch = async (searchFilter: SearchFilter) => {
    const toString = JSON.stringify(searchFilter);
    try {
      const response = await axios.post<string>(
      //  'http://localhost:8080/inventories/search', 
      inventroriesSearchUrl,
        searchFilter,       
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        console.log('inventory search result:', response);
    }       
    catch (error) {
      console.error('Request failed:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Server responded with an error:', error.response);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
      } else {
        console.error('Unexpected error:', error);
      } 
    }   
  };


  return (
    <div>
      <select onChange={handleCategoryChange}>
        <option value="">Select a category</option>
        {categories.map(category => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      
      {selectedCategory && (
        <div>
          <p>Selected Category ID: {selectedCategory.id}</p>
          <p>Selected Category Name: {selectedCategory.name}</p>
          <p>Selected Category description: {selectedCategory.description}</p>
          <DynamicForm 
            parameters={selectedCategory.parameters} 
            categoryId={selectedCategory.id} 
            onSubmit={handleFormSubmit}/>
        </div>
      )}
    </div>
  );
};

export default CategoryComponent;
