import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Privilege } from '../types/Privileges';
import axios from 'axios';

interface PrivilegesProps {
  token: string | null;
}

const PrivilegesComponent: React.FC<PrivilegesProps> = ({ token }) => {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);
  const [error, setError] = useState<string | null>(null);
  const privilegesUrl : string = process.env.REACT_APP_API_URL + "/security/privileges";

  useEffect(() => {
    const fetchPrivileges = async () => {
      try {    
        /*                                       
        const response = await axios.get<Privilege[]>('http://localhost:8080/privileges', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          */
        const response = await axios.get<Privilege[]>(
          //'http://localhost:8080/security/privileges', 
          privilegesUrl,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          });

        setPrivileges(response.data);
      } 
      catch (error) {
        console.error('Request failed:', error);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error('Server responded with an error:', error.response);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
        } else {
          console.error('Unexpected error:', error);
        } 
      }

    };

    fetchPrivileges();
  }, [token]);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <ul>
      {privileges.map((privilege) => (
        <li key={privilege.id}>{privilege.id}: {privilege.name}: {privilege.description}</li>
      ))}
    </ul>
  );
};

export default PrivilegesComponent;
