import React, { useState } from 'react';
import CategoryComponent from './CategoryComponent';
import PrivilegesComponent from './PrivilegesComponent';


interface MenuComponentProps {
    token: string | null;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ token }) => {
  const [activeComponent, setActiveComponent] = useState<string | null>(null);

  return (
    <div>
      <nav>
        <ul>
          <li><a href="#" onClick={() => setActiveComponent('categories')}>Categories</a></li>
          <li><a href="#" onClick={() => setActiveComponent('privileges')}>Privileges</a></li>
        </ul>
      </nav>
      
      <div>
        {activeComponent === 'categories' && <CategoryComponent token={token}  />}
        {activeComponent === 'privileges' && <PrivilegesComponent token={token} />}
      </div>
    </div>
  );
};

export default MenuComponent;
