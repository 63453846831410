import React, { useState } from 'react';
import { Category, Parameter } from '../types/Category';
import { SearchFilter, SearchParameter } from '../types/SearchFilter';

const DynamicForm: React.FC<{ 
    parameters: Parameter[], 
    categoryId: string, 
    onSubmit: (searchFilter: SearchFilter) 
  => void }> = ({ parameters, categoryId, onSubmit }) => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [submittedData, setSubmittedData] = useState<{ [key: string]: any } | null>(null);
  const [useRange, setUseRange] = useState<Record<string, boolean>>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleRangeToggle = (paramName: string) => {
    setUseRange(prevState => ({
      ...prevState,
      [paramName]: !prevState[paramName],
    }));
    setFormData(prevData => ({
      ...prevData,
      [paramName]: {}, // Reset values when switching between exact and range
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    // Handle form submission logic here
 /*
    const searchParameters: SearchParameter[] = parameters.map(param => ({
      name: param.name,
      value: formData[param.name] || '',
      dataType: param.dataType,
    }));
*/

    const searchParameters: SearchParameter[] = parameters.map(param => {
        const value = formData[param.name];
        let formattedValue: string;
        let dataType = param.dataType;

        if (useRange[param.name]) {
            // If range is used, add dataType to the value object
            formattedValue = {
                ...value,
                dataType: param.dataType,
            };


            // If range is used, modify the dataType accordingly
            if (param.dataType === 'INTEGER') {
                dataType = 'RANGE_INTEGER';
            } else if (param.dataType === 'DOUBLE') {
                dataType = 'RANGE_DOUBLE';
            }
            //formattedValue = JSON.stringify(value);
        } else {
            formattedValue = value as string;
        }

        return {
            name: param.name,
            value: formattedValue,
            dataType: dataType,
        };
    });

    const searchFilter: SearchFilter =  {
      categoryId,
      searchParameters,
    };

    console.log("searhFilter:", searchFilter);
    onSubmit(searchFilter);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, paramName: string, type: 'minimum' | 'maximum' | 'exact' | 'default' = 'default') => {
    const { value, checked, type: inputType } = event.target;

    if (inputType === 'checkbox') {
        setFormData({
          ...formData,
          [paramName]: checked, // true if checked, false if unchecked
        });
    } else if (type === 'minimum' || type === 'maximum') {
      setFormData(prevData => ({
        ...prevData,
        [paramName]: {
          ...((prevData[paramName] as { minimum?: string; maximum?: string}) || {}),
          [type]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [paramName]: value,
      });
    }
  };

  

  const renderInput = (param: Parameter) => {
    switch (param.dataType) {
      case 'STRING':
        return (
          <input
            type="text"
            name={param.name}
            placeholder={param.description}
            value={formData[param.name] || ''}
            onChange={handleChange}
          />
        );
      case 'BOOLEAN':
        return (
          <input
            type="checkbox"
            name={param.name}
            checked={!!formData[param.name]}
            onChange={handleChange}
          />
        );
      case 'NUMBER':
        return (
          <input
            type="text"
            name={param.name}
            placeholder={param.description}
            value={formData[param.name] || ''}
            onChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {parameters.map((param, index) => (
        <div key={index}>
          <label>{param.name} ({param.dataType}):</label>
          {param.dataType === 'INTEGER' || param.dataType === 'DOUBLE'  ? (
            <>
              <div>
                <label>
                  <input
                    type="radio"
                    checked={!useRange[param.name]}
                    onChange={() => handleRangeToggle(param.name)}
                  />
                  Exact
                </label>
                <label>
                  <input
                    type="radio"
                    checked={useRange[param.name]}
                    onChange={() => handleRangeToggle(param.name)}
                  />
                  Range
                </label>
              </div>
              {useRange[param.name] ? (
                <>
                  <input
                    type="number"
                    name={`${param.name}-minimum`}
                    placeholder="minimum"
                    onChange={e => handleInputChange(e, param.name, 'minimum')}
                  />
                  <input
                    type="number"
                    name={`${param.name}-maximum`}
                    placeholder="maximum"
                    onChange={e => handleInputChange(e, param.name, 'maximum')}
                  />
                </>
              ) : (
                <input
                  type="number"
                  name={param.name}
                  placeholder={param.description}
                  onChange={e => handleInputChange(e, param.name, 'exact')}
                />
              )}
            </>
          ) : param.dataType === 'BOOLEAN' ? (
            <input
              type="checkbox"
              name={param.name}
              onChange={e => handleInputChange(e, param.name)}
            />
          ) : (
            <input
              type={param.dataType.toLowerCase()}
              name={param.name}
              placeholder={param.description}
              onChange={e => handleInputChange(e, param.name)}
            />
          )}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  ); 
};
export default DynamicForm