import './otel-setup'; // Ensure this is imported first
import React, { useState } from 'react';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import PrivilegesComponent from './components/PrivilegesComponent';
import MenuComponent from './components/MenuComponent';

const App: React.FC = () => {
  const [view, setView] = useState<'home' | 'registration' | 'login' | 'menu' | null>('home');

  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState<string >('');

  const switchToRegistration = () => {
    setIsRegistered(false);
    setIsLoggedIn(false);
  };

  
  const switchToLogin = () => {
    setIsRegistered(true);
  };

  const handleLoginSuccess = (token: string) => {
    setToken(token);
    setIsLoggedIn(true);
    setView('menu');
  };


  return (
    <div>
      {view === 'home' && (
        <div>
          <button onClick={() => setView('registration')}>Register</button>
          <button onClick={() => setView('login')}>Login</button>
        </div>
      )}
      {view === 'registration' && (
        <RegistrationForm onSwitchToLogin={() => setView('login')} />
      )}
      {view === 'login' && (
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      )}
      {token && view === 'menu' && (<MenuComponent token={token} />)}
      
    </div>
  );
};

export default App;
